import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Hospitals',
  components: {

  },
  props: {

  },
  emits: {
      
  },
})
export default class Hospitals extends Vue {
    
}
