<template>
<!-- 
https://github.com/johnkomarnicki/navigation-menu/blob/Starter-Files/src/App.vue
https://www.youtube.com/watch?v=u2AwJAFeaKc
 -->
<header :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
        <div class="branding">
            <router-link class="link-home" :to="{ name: 'Hospitals'}"><img src="@/assets/logo_white.svg" alt=""></router-link>
        </div>
        <ul v-show="!mobile" class="navigation">
            <li><router-link class="link" :to="{ name: 'Hospitals'}">{{ $tc('hospitals') }}</router-link></li>        
            <li><router-link class="link" :to="{ name: 'Projects'}">{{ $tc('projects') }}</router-link></li>
            <li><router-link class="link" :to="{ name: 'Devices'}">{{ $tc('devices') }}</router-link></li>
            <li><router-link class="link" :to="{ name: 'Assets'}">{{ $tc('assets') }}</router-link></li>
            <li><router-link class="link" :to="{ name: 'Tests'}">{{ $tc('tests') }}</router-link></li>
            <li><router-link class="link" :to="{ name: 'About'}">{{ $tc('about') }}</router-link></li>
            <li>      
                <select v-model='$i18n.locale' class='language' >
                    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value='lang' > 
                        {{  lang }}   
                    </option>
                </select>
            </li>
            <li  v-if="!userStore.getters.isLoggedIn">
                       <FormLogin />
            </li>
            <li v-else>
                <h2>Welcome, {{ userStore.state.name }}</h2>
                <router-link class="link" :to="{ name: 'Table'}">Tabella </router-link>
                <button @click="userStore.logout()">Logout</button>
            </li>
        </ul>
        <div class="icon">   
            <i @click="toggleMobileNav" v-show="mobile"  :class="{ 'icon-active': mobileNav }">
                <img src="@/assets/burger_menu_white.svg" alt="">
            </i>
        </div>
        <transition name="mobile-nav">
            <ul v-show="mobileNav" class="dropdown-nav">
                <li><router-link class="link" :to="{ name: 'Hospitals'}" @click="mobileNav = !mobileNav">{{ $tc('hospitals') }}</router-link></li>        
                <li><router-link class="link" :to="{ name: 'Projects'}" @click="mobileNav = !mobileNav">{{ $tc('projects') }}</router-link></li>
                <li><router-link class="link" :to="{ name: 'Devices'}" @click="mobileNav = !mobileNav">{{ $tc('devices') }}</router-link></li>
                <li><router-link class="link" :to="{ name: 'Assets'}" @click="mobileNav = !mobileNav">{{ $tc('assets') }}</router-link></li>
                <li><router-link class="link" :to="{ name: 'Tests'}" @click="mobileNav = !mobileNav">{{ $tc('tests') }}</router-link></li>
                <li><router-link class="link" :to="{ name: 'About'}" @click="mobileNav = !mobileNav">{{ $tc('about') }}</router-link></li>
                <li>      
                    <select v-model='$i18n.locale' class='language' >
                        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value='lang' > 
                            {{  lang }}   
                        </option>
                    </select>
                </li>
                <li  v-if="!userStore.getters.isLoggedIn">
                       <FormLogin />
                </li>
                <li v-else>
                    <h2>Welcome, {{ userStore.state.name }}</h2>
                    <router-link class="link" :to="{ name: 'Table'}">Tabella </router-link>
                    <button @click="userStore.logout()">Logout</button>
                </li>
            </ul>
        </transition>
    </nav>
</header>
</template>

<script>
import userStore from '@/store/user';
import FormLogin from '@/components/login/formLogin.vue';

export default {
    name: 'navigation',
    components: { FormLogin },
    data() {
        return {
            langs: ['tr', 'en'],

            scrolledNav: null,
            mobile: false,
            mobileNav: false,
            windowWidth: null,
        };
    },
    setup() {
        return { userStore }
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    methods: {
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav;
        },

        updateScroll() {
            const scrollPosition = window.scrollY;
            if(scrollPosition > 50) {
                this.scrolledNav = true;
                return;
            } 
            this.scrolledNav = false;
        },

        checkScreen() {
            this.windowWidth = window.innerWidth;
            if(this.windowWidth <= 750){
                this.mobile = true;
                return;
            }
            this.mobile = false;
            this.mobileNav = false;
            return;
        },
    }
};
</script>

<style lang="scss" scoped>
header {
    background-color: rgba(0,0,0,0.8);
    z-index: 99;
    width: 100%;
    position: fixed;
    transition: 0.5s ease all;
    color: #fff;



    nav {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 12px 0;
        transition: 0.5s ease all;
        width: 90%;
        margin: 0 auto;

        @media (min-width: 1140px) {
            max-width: 1140px;
        }

        ul, 
        .link {
            font-weight: 500;
            color: #fff;
            list-style: none;
            text-decoration: none;
            
            a.router-link-exact-active {
                color: #42b983;
            }
        }

        li {
            text-transform: uppercase;
            padding: 2px;
            margin-left: 16px;
        }

        .link{
            font-size: 12px;
            transition:  0.5s ease all;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;
        
            &:hover {
                color: #00afea;
                border-color: #00afea;
            }

            &-home {
                border-bottom: none;
                text-decoration: none;
            }
        }

        .branding {
            display: flex;
            align-items: center;


            img {
                width: 50px;
                transition: 0.5s ease all;
            }
        }

        .navigation {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-start;
        }

        .icon {
            display: flex;
            align-items: center;
            position: absolute;
            top:0;
            right: 10px;
            height: 100%;
            
            i {
                cursor: pointer;
                font-size: 24px;
                transition: 0.8s ease all;
            }
        }

        .icon-active {
            transform: rotate(180deg);
        }

        .dropdown-nav {
            display: flex;
            flex-direction: column;
            position: fixed;
            width: 100%;
            max-width: 250px;
            height: 100%;
            background-color: #fff;
            top: 0;
            left: 0;

            li {
                margin-left: 0;
                .link{
                    color: #000;
                }
            }
        }

        .mobile-nav-enter-active, 
        .mobile-nav-leave-active {
            transition: 0s ease all;
        }

        .mobile-nav-enter-from
        .mobile-nav-enter-to {
            transform: translateX(-250px);
        }

        .mobile-nav-enter-to {
            transform: translateX(0);
        }
    }
}

.scrolled-nav {
    background-color: #000;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);

    nav {   
        padding: 8px 0;

        .branding {
            img {
                width: 40px;
                box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
            }
        }   
    }
}
</style>