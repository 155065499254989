<template>
  <div class="hospitals">
    Hospitals...
  </div>
</template>
<script src="./hospitals.ts"/>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>