
import { defineComponent, reactive } from 'vue'
import userStore from '@/store/user'
export default defineComponent ({
  name: "FormLogin",
  setup() {
    const form = reactive({
      username: '',
      password: ''
    })
    const onSubmit = () => {
      userStore.login(form.username, form.password)
      form.username = ''
      form.password = ''
    }
    return { form, userStore, onSubmit }
  }
})
